<template>
  <div class="col-xl-4 col-lg-5">
    <div class="card">
      <div class="card-header pb-0">
        <h4 class="card-title mb-0">Mi perfil</h4>
        <div class="card-options">
          <a
            class="card-options-collapse"
            href="#"
            data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
          ><a class="card-options-remove" href="#" data-bs-toggle="card-remove"
            ><i class="fe fe-x"></i
          ></a>
        </div>
      </div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="profile-title">
            <div class="d-lg-flex d-block align-items-center">
              <!-- <img class="img-70 rounded-circle" alt="" :src="img" /> -->
              <imagePreview
                @upload-image="uploadFile"
                :initialValue="initialValue"
              />
              <div class="flex-grow-1">
                <h3 class="mb-1 f-20 txt-primary">
                  <router-link to="/mi-perfil">
                    {{ userInfo.first_name }}
                    {{ userInfo.last_name }}</router-link
                  >
                </h3>
                <p class="f-12 mb-0">Psicologo</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label f-w-500">Correo</label>
          <input
            class="form-control"
            placeholder="your-email@domain.com"
            v-model="user.email"
          />
        </div>
        <div class="mb-3">
          <label class="form-label f-w-500">Contraseña</label>
          <input class="form-control" type="password" v-model="user.password" />
        </div>
        <div class="form-footer">
          <button class="btn btn-primary btn-block" @click="putUser">
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import imagePreview from "@/components/imagePreview/index.vue";
import { uploadFile } from "../../../utils/s3Service";
export default {
  components: {
    imagePreview,
  },
  data() {
    return {
      img: "https://dev.sigma.net.pe/img/man.76101d57.png",
      user: {
        first_name: "",
        last_name: "",
        gender: "",
        email: "",
        password: "",
      },
      initialValue: "",
      form: {
        profile_photo: "",
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user || {},
    }),
  },
  async mounted() {
    const data = {
      id: localStorage.getItem("userId"),
    };
    await this.getUser({ payload: data });
    this.setData();
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      updateUser: "user/updateUser",
    }),
    setData() {
      this.user.first_name = this.userInfo.first_name;
      this.user.last_name = this.userInfo.last_name;
      this.user.gender = this.userInfo.gender;
      this.user.email = this.userInfo.email;
      this.form.id = this.userInfo.id;
      this.form.user_type = this.userInfo.user_type;
      this.form.profile_photo = this.userInfo.profile_photo;
      this.initialValue = this.userInfo.profile_photo;
    },
    async putUser() {
      try {
        let data = {};
        if (this.user.password !== "") {
          data = {
            id: this.userInfo.id,
            user_type: this.userInfo.user_type,
            email: this.user.email,
            password: this.user.password,
          };
        } else {
          data = {
            id: this.userInfo.id,
            user_type: this.userInfo.user_type,
            email: this.user.email,
          };
        }

        const response = await this.updateUser({ payload: data });
        this.$toast.show("Actualizado exitosamente ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.show("Verificar bien los datos de ingreso", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
        console.error("Error uploading file:", error);
      }
    },
    uploadFile(event) {
      if (event) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          event.target.files[0]
        )
          .then((data) => {
            this.form.profile_photo = data.Location;
            this.updateUser({ payload: this.form });
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
  },
};
</script>
