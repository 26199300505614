<template>
  <div class="row">
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" v-for="f in friends">
      <div class="card custom-card" v-if="!hasParams || f.status !== 'pending'">
        <div class="card-header"><img class="img-fluid" :src="f.photo_large" alt=""></div>
        <div class="card-profile"><img class="rounded-circle" :src="f.profile_photo" alt=""></div>
        <div class="text-center profile-details">
          <h4>{{ f.name }}</h4>
          <h6>Psicologo</h6>
        </div>

        <div class="card-footer row">
          <div class="col-6 col-sm-6">
            <h6>Amigos</h6>
            <h3><span class="counter">1</span></h3>
          </div>
          <div class="col-6 col-sm-6">
            <h6>Likes</h6>
            <h3><span class="counter">{{ f.likes }}</span>M</h3>
          </div>
        </div>
        <button  @click="confirmedFriend(f.id)" v-if=" f.status === 'pending'" class="btn btn-secondary">Confirmar Amistad</button>
      </div>
    </div>

  </div>
</template>
<script>
import imageMixin from "@/mixins/commen/imageMixin"
import { mapActions, mapState } from "vuex";
export default {
  mixins: [imageMixin],
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapState({
      friends: (state) => state.friends.friends,
    }),
    hasParams() {
      return Object.keys(this.$route.params).length > 0;
    },
  },
  methods: {
    ...mapActions({
      updateFriend: "friends/updateFriend",
      getFriends:"friends/getFriends"
    }),
    async confirmedFriend(id){
      const data = {
        id:id,
        status_of_friend: "approved"
      }
      await this.updateFriend({payload:data});
      const info = {
        id: localStorage.getItem("userId"),
      };
      await this.getFriends({payload:info})
    }
  }
}
</script>