<template>
  <Breadcrumbs main="Home" class="h3_titulos" title="Mi Comunidad" />
  <div class="main-container">
    <!-- Contenido principal -->
    <div class="content">
      <!-- Formulario para crear un nuevo post con Rich Text Editor (tiptap-vuetify) -->
      <div class="new-post">
        <h3>Crear un nuevo post</h3>
        <!-- <v-text-editor v-model="newPostText" :options="editorOptions"></v-text-editor> -->
        <div class="card-body add-post">
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label"
              >Ingresa el contenido</label
            >
            <textarea
              v-model="form.content"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Ingresar aquí contenido del Post"
              required
            ></textarea>
          </div>
          <img
            v-if="form.image_url"
            :src="form.image_url"
            alt="image post"
            height="150"
          />
          <div class="add-image">
            <span
              >Agregar a la publicacion
              <div class="tooltip-container">
                <label for="formFile" class="add-image-btn"
                  ><img src="/img/iconoImage.png" alt="icono add image"
                /></label>
                <div class="tooltip-content">Agregar imagen</div>
              </div></span
            >
            <input
              class="image-btn"
              type="file"
              id="formFile"
              @change="handleFileChangeInform"
            />
          </div>
        </div>
        <button class="btn btn-primary" @click="createNewPost">Publicar</button>
      </div>

      <!-- Lista de posts -->
      <div class="posts row mt-5">
        <FeedPost v-for="post in posts" :key="post.id" :post="post" />
      </div>
    </div>

    <!-- Sidebar para tendencias (lado derecho) -->
    <div class="sidebar sidebar_tendencias ms-5">
      <h2 class="h3_titulos_sidebar">Tendencias</h2>
      <ul class="trending-list" v-for="tag in tags" :key="tag.id">
        <li @click="getPostsForTendence(tag.name)">
          {{ tag.name }}
          <p class="counter-tags">{{ tag.counter_tag + 1 }} Publicaciones</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FeedPost from "@/components/myFeedSections/FeedPost.vue";
import Tiptap from "@/components/Tiptap.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import dropZone from "@/components/common/dropZone.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapState, mapActions } from "vuex";
import { uploadFile } from "../../utils/s3Service";

export default {
  components: {
    FeedPost,
    Tiptap,
    ckeditor: CKEditor.component,
    dropZone,
  },
  data() {
    return {
      activeTab: "Para ti",
      previewImage: null,
      newPostText: "",
      editorData: "",
      editorConfig: {
        toolbar: [
          "imageUpload",
          "|",
          "heading",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
        image: {
          upload: {
            types: ["png", "jpg", "jpeg", "gif", "bmp", "webp"],
          },
        },
      },
      editor: ClassicEditor,
      multiValue: null,
      selectedFile: "",
      comment: {
        user_id: "",
        post_id: "",
        message_box: "",
      },

      form: {
        user_id: "",
        title: "",
        image_url: "",
        content: "",
        likes: 0,
        tag: "Ejemplo",
      },
    };
  },
  computed: {
    ...mapState({
      posts: (state) => state.feed.posts,
      tags: (state) => state.feed.tags,
      userInfo: (state) => state.user.user || {},
    }),
  },
  async mounted() {
    await this.getPosts();
    await this.getTags();
  },
  methods: {
    ...mapActions({
      getPosts: "feed/getPosts",
      getTags: "feed/getTags",
      createPost: "feed/createPost",
      getPostsForTags: "feed/getPostsForTags",
    }),
    onReady(event) {
      event.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((response) => {
              const image = response;
              this.selectedFile = response;
              this.uploadFilesInform();
              const reader = new FileReader();
              reader.readAsDataURL(image);
              reader.onload = (e) => {
                const imageUrl = e.target.result;
                resolve({
                  default: imageUrl,
                });
              };
            })
            .catch((error) => {
              console.log(error);
            });
        });
      };
    },
    handleFileChangeInform(event) {
      this.selectedFile = event.target.files[0];
      this.uploadFilesInform();
    },
    async getPostsForTendence(tag) {
      try {
        const payload = {
          tag: tag,
        };
        console.log("legga", payload);
        await this.getPostsForTags(payload);
      } catch (e) {
        console.log(e);
      }
    },
    uploadFilesInform() {
      if (this.selectedFile) {
        uploadFile(
          "uploads-sigma-pro",
          this.selectedFile.name,
          this.selectedFile
        )
          .then((data) => {
            this.form.image_url = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    async createNewPost() {
      const userID = localStorage.getItem("userId");
      this.form.user_id = userID;
      const tagMatches = this.form.content.match(/#(\w+)/g);
      const hashtag = tagMatches ? tagMatches.join(", ") : "";
      this.form.tag = hashtag.replace(/^#/, "");
      try {
        await this.createPost({ payload: this.form });
        this.$toast.show("Post Publicado ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
        await this.getPosts();
        this.form.content = "";
        this.form.image_url = "";
        this.form.tag = "";
        await this.getTags();
      } catch (e) {
        console.log("error");
      }
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  margin-left: 20px;
  position: relative;
  display: inline-block;
}
.counter-tags {
  font-size: 10px;
  height: 10px;
}
.tooltip-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.add-image {
  height: 40px;
  border-radius: 10px;
  width: 100%;
}

.add-image-btn {
  cursor: pointer;
}

.image-btn {
  display: none;
}

.h3_titulos {
  font-size: 24px;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.h3_titulos_sidebar {
  font-size: 20px;
  color: #2b2b2b;
  margin: 0 0 10px 0;
}

.main-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin-top: 0;
}

.sidebar {
  width: 300px;
  /*background-color: #f0f0f0;*/
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.trending-list {
  list-style-type: none;
  padding: 0;
}

.trending-list li {
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f2f2f2a1;
  padding: 1px 5px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
}

.content {
  flex: 1;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  margin-right: 20px;
}

.tabs button.activeTab {
  color: #022b95;
  border-bottom: 2px solid #022b95;
}

.new-post {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.new-post h3 {
  font-size: 20px;
  color: #2b2b2b;
  margin: 0 0 10px 0;
}

.new-post textarea,
.new-post input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #5c5c5ca3;
  border-radius: 4px;
}

.new-post button {
  background-color: #022b95;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.posts {
  /* Estilos para los posts */
}

/* Estilos para el componente FeedPost */
.feed-post {
  /* Estilos para los posts */
}

/* Estilos para el componente FeedPost (puedes usar los mismos estilos que en tu componente anterior) */
</style>
