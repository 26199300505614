<template>
  <div class="col-xl-3 box-col-30 xl-30">
    <div
      class="default-according style-1 faq-accordion job-accordion"
      id="accordionoc1"
    >
      <div class="row">
        <div class="col-xl-12 col-md-6 box-col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="p-0">
                <button
                  class="btn btn-link ps-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseicon2"
                  :aria-expanded="isActive ? 'true' : 'false'"
                  aria-controls="collapseicon2"
                  :class="[isActive ? 'active' : '', 'collapsible']"
                  v-on:click="toggle"
                >
                  Mi Perfil
                </button>
              </h5>
            </div>
            <div :class="[isActive ? 'block' : ' show']" v-show="isActive">
              <div class="card-body filter-cards-view">
                <div class="filter-view-group">
                  <span class="f-w-600">Nombres Completo :</span>
                  <p class="mb-0">
                    {{ userInfo.first_name }} {{ userInfo.last_name }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Edad :</span>
                  <p class="mb-0">
                    {{ userInfo.age }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Género :</span>
                  <p class="mb-0">
                    {{ userInfo.gender === "male" ? "Masculino" : "Femenino" }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Universidad</span>
                  <p class="mb-0">
                    {{ university }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Formación terapéutica</span>
                  <p class="mb-0">
                    {{ user.therapeuticTraining }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Instrucción</span>
                  <p v-if="userInfo.undergraduate" class="mb-0">
                    - Pre grado/bachiller
                  </p>
                  <p v-if="userInfo.master" class="mb-0">- Maestría</p>
                  <p v-if="userInfo.doctorade" class="mb-0">- Doctorado</p>
                </div>
                <div class="filter-view-group">
                  <span
                    class="f-w-600"
                    style="font-weight: bold; font-size: 1.1rem"
                    >PERFIL LABORAL</span
                  >
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Colegiado</span>
                  <p v-if="userInfo.collegiate" class="mb-0">Si</p>
                  <p v-else class="mb-0">No</p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Habilitado</span>
                  <p v-if="userInfo.enable_sys" class="mb-0">Si</p>
                  <p v-else class="mb-0">No</p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Número de colegiatura</span>
                  <p class="mb-0">
                    {{ userInfo.collegiate }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">CDR</span>
                  <p v-if="userInfo.CDR" class="mb-0">Si</p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Atiende</span>
                  <p v-if="user.workProfile.attend.children" class="mb-0">
                    - Niños y adolecentes
                  </p>
                  <p v-if="user.workProfile.attend.young" class="mb-0">
                    - Jóvenes
                  </p>
                  <p v-if="user.workProfile.attend.adults" class="mb-0">
                    - Adultos
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Precio por sesión</span>
                  <p class="mb-0">
                    {{ userInfo.price_session }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Modalidad</span>
                  <p v-if="userInfo.onsite" class="mb-0">- Presencial</p>
                  <p v-if="userInfo.remote" class="mb-0">- Virtual</p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Abordaje en</span>
                  <p class="mb-0">
                    {{ approach }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Direccion de consultorio</span>
                  <p class="mb-0">
                    {{ userInfo.address }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Nacionalidad</span>
                  <p class="mb-0">
                    {{ userInfo.nationality }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Departamento</span>
                  <p class="mb-0">
                    {{ userInfo.state_country }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Provincia</span>
                  <p class="mb-0">
                    {{ userInfo.province }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Distrito</span>
                  <p class="mb-0">
                    {{ userInfo.district }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Correo</span>
                  <p class="mb-0">
                    {{ userInfo.email }}
                  </p>
                </div>
                <div class="filter-view-group">
                  <span class="f-w-600">Whatsapp</span>
                  <p class="mb-0">
                    {{ userInfo.phone }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <followersView/>
                      <followingView/> -->
        <!-- <latestPhotos/>
                      <friendsView/> -->
        <div class="col-xl-12 col-md-6 box-col-12">
          <!-- <div class="card"><img class="img-fluid" src="@/assets/images/social-app/timeline-4.png" alt=""></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import followersView from "@/components/users/profile/followersView.vue";
import followingView from "@/components/users/profile/followingView.vue";
import latestPhotos from "@/components/users/profile/latestPhotos.vue";
import friendsView from "@/components/users/profile/friendsView.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    followersView,
    followingView,
    latestPhotos,
    friendsView,
  },
  data() {
    return {
      show: true,
      isActive: true,
      university: "",
      approach: "",
      user: {
        name: "Rodrigo",
        lastName: "Calle Torres",
        age: 25,
        genre: "Masculino",
        college: "Cibertec",
        therapeuticTraining: "Contextual",
        instruction: {
          bachelor: true,
          master: false,
          phd: true,
        },
        workProfile: {
          isCollege: true,
          isActive: true,
          numberCollege: "441554",
          cdr: true,
          attend: {
            children: true,
            young: true,
            adults: false,
          },
          pricePerSession: 200,
          modality: {
            inPerson: true,
            remote: false,
          },
          approach: "Otros",
          address: "Miguel Dasso 126",
          nationality: "Peru",
          departament: "Lima",
          province: "Lima",
          district: "San Miguel",
          email: "rcalletorres@gmail.com",
          whatsapp: "963547854",
          isVisible: true,
          isDiscount: false,
        },
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user || {},
      universidades: (state) => state.user.universidades,
      approachs: (state) => state.approach.approachs,
    }),
  },
  async mounted() {
    await this.getApproachs();
    this.searchForId();
    this.searchForIdApproach();
  },
  methods: {
    ...mapActions({
      getApproachs: "approach/getApproachs",
    }),
    searchForId() {
      const university = this.universidades.find(
        (universidad) => universidad.id === this.userInfo.university_id
      );
      if (university) {
        this.university = university.name;
      } else {
        console.log("Universidad no encontrada");
      }
    },
    searchForIdApproach() {
      const approach = this.approachs.find(
        (app) => app.id === this.userInfo.approach_id
      );
      if (approach) {
        this.approach = approach.name;
      } else {
        console.log("Universidad no encontrada");
      }
    },
  },
};
</script>
