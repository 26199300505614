<template>
  <div class="card_tab">
    <div class="content-header">
      <Breadcrumbs main="" title="Motivo de consulta" />
      <button class="small-primary-button" @click="createReason">
        <i class="fa fa-bookmark-o" aria-hidden="true"></i>
        Guardar y continuar
      </button>
    </div>
    <div class="card-body">
      <form class="theme-form mega-form">
        <div class="mb-3">
          <label class="col-form-label">Motivo de Consulta</label>

          <textarea
            class="form-control textarea_motivo_consulta"
            id="exampleFormControlTextarea4"
            rows="3"
            v-model="form.reason"
          ></textarea>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
.card_tab {
  background: white;
  padding: 10px;
}
.textarea_motivo_consulta{
  height: 200px;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  props: ["patient"],
  data() {
    return {
      form: {
        reason: "",
      },
    };
  },
  watch: {
    patient: {
      handler(newValue, oldValue) {
        this.form.reason = newValue.reason;
      },
    },
  },
  methods: {
    ...mapActions({
      setStep: "patients/setStep",
    }),
    createReason() {
      this.$emit("join-data", this.form);
      // this.form.id = this.$route.params.id;
      this.$toast.show("Continuando exitosamente ", {
        theme: "outline",
        position: "top-right",
        icon: "times",
        type: "success",
        duration: 2000,
      });
      const step = "personalHistory";
      this.setStep({ payload: step });
    },
  },
};
</script>
