<template>
  <div class="account-user"><vue-feather type="user"></vue-feather></div>
  <ul class="profile-dropdown onhover-show-div">
    <li>
      <router-link to="/mi-perfil">
        <vue-feather type="user"></vue-feather
        ><span>Mi Perfil</span></router-link
      >
    </li>
    <li>
      <a @click="logout">
        <vue-feather type="log-in"></vue-feather><span>Cerrar Sesión</span></a
      >
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  methods: {
    logout: function () {
      this.$router.replace("/auth/login");
      localStorage.clear();
    },
  },
};
</script>
