<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card">
          <div>
            <div>
              <a class="logo w-100 text-center" style="height: 100%"
                ><router-link to="/">
                  <img
                    class="img-fluid for-light"
                    style="width: 150px; margin-bottom: 2rem; margin-top: 4rem"
                    src="/img/logo.png"
                    alt="looginpage" /></router-link
              ></a>
            </div>
            <div class="login-main">
              <div class="theme-form">
                <h4 class="text-center">Regístrate</h4>
                <p class="text-center">Es rápido y fácil</p>
                <div class="form-group">
                  <label class="col-form-label pt-0">Nombres</label>
                  <div class="row g-2">
                    <div class="col-6">
                      <input
                        v-model="form.first_name"
                        class="form-control"
                        type="text"
                        placeholder="Nombres"
                      />
                    </div>
                    <div class="col-6">
                      <input
                        v-model="form.last_name"
                        class="form-control"
                        type="text"
                        placeholder="Apellidos"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Correo Electronico</label>
                  <input
                    v-model="form.email"
                    class="form-control"
                    type="email"
                    placeholder="Test@gmail.com"
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <div class="form-input position-relative">
                    <input
                      v-model="form.password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control"
                      name="login[password]"
                      placeholder="*********"
                    />
                    <div class="show-hide" @click="togglePasswordVisibility">
                      <span :class="{ show: showPassword }">{{
                        showPassword ? "Ocultar" : "Mostrar"
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row g-2">
                    <div class="col-6">
                      <label class="col-form-label">Sexo</label>
                      <select
                        id="inputGenre"
                        class="form-select"
                        v-model="form.gender"
                      >
                        <option selected>Selecciona...</option>
                        <option value="male">Masculino</option>
                        <option value="female">Femenino</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <label class="col-form-label">Categoria</label>
                      <select
                        id="inputGenre"
                        class="form-select"
                        v-model="form.user_type"
                        required
                      >
                      <option value="" disabled selected>Selecciona...</option>
                        <!-- <option value="student">Estudiante</option> -->
                        <option value="profesional">Profesional</option>
                        <!-- <option value="institucional">Institución</option> -->
                      </select>
                      <div v-if="errorMessages.user_type" class="error-message">
                        {{ errorMessages.user_type }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="form.user_type === 'institucional'"
                  class="form-group"
                >
                  <label class="col-form-label">Nombre de tu institución</label>
                  <input
                    v-model="form.inst_name"
                    class="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div
                  v-if="form.user_type === 'institucional'"
                  class="form-group"
                >
                  <label class="col-form-label">RUC</label>
                  <input
                    v-model="form.RUC"
                    class="form-control"
                    type="text"
                    placeholder="ruc"
                  />
                </div>
                <div v-if="form.user_type === 'profesional'" class="form-group">
                  <label class="col-form-label">Número de Colegiatura</label>
                  <input
                    v-model="form.collegiate"
                    class="form-control"
                    type="text"
                    placeholder="# colegiatura"
                  />
                </div>
                <div v-if="form.user_type === 'profesional'" class="form-group">
                  <label class="col-form-label">CDR a la que perteneces</label>

                  <select
                    v-model="form.cdr_id"
                    id="inputGenre"
                    class="form-select"
                  >
                    <option
                      v-for="item in cdrs"
                      :key="item.value"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <div class="checkbox p-0">
                    <input v-model="free" id="checkbox1" type="checkbox" />
                    <label class="text-muted" for="checkbox1"
                      >Inicio de prueba gratis de 30 días</label
                    >
                  </div>
                  <div class="checkbox p-0">
                    <input v-model="condition" id="checkbox2" type="checkbox" />
                    <label class="text-muted" for="checkbox2"
                      >Acepto terminos y condiciones</label
                    >
                  </div>
                  <button
                    @click="submitUser"
                    :disabled="!free || !condition"
                    class="btn btn-primary btn-block w-100 mt-3"
                  >
                    Iniciar
                  </button>
                </div>
                <div class="center">
                  <a class="text-center"
                    ><router-link to="/"> ir al login</router-link></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-hide span:before {
  display: none !important;
}
.show-hide .show:before {
  display: none !important;
}
.error-message {
  color: #ff0000; /* Color rojo o el que prefieras */
  margin-top: 10px;
}
</style>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      errorMessages: {
        user_type: "",
      },
      free: false,
      condition: false,
      showPassword: false,
      form: {
        first_name: "",
        last_name: "",
        gender: "",
        email: "",
        user_type: "",
        password: "",
        person_type: "natural",
        inst_name: "",
        RUC: "",
        cdr_id: "",
        psychologist_id: "",
        director_id: "",
      },
    };
  },
  computed: {
    ...mapState({
      cdrs: (state) => state.auth.cdrs,
    }),
  },
  async mounted() {
    await this.getCdrs();
  },
  methods: {
    ...mapActions({
      createtUser: "user/createUser",
      getCdrs: "auth/getCdrs",
    }),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async submitUser() {
      this.errorMessages = {
        user_type: "",
      };

      if (this.form.user_type === "") {
        this.errorMessages.user_type = "Seleccionar una categoría válida";
      }

      const hasErrors = Object.values(this.errorMessages).some(
        (message) => message !== ""
      );

      if (hasErrors) {
        console.log("Campos obligatorios", this.errorMessages);
        return;
      }

      try {
        const response = await this.createtUser({ payload: this.form });
        this.$router.push("/mi-perfil");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.user_id);
        localStorage.setItem("user", this.form.email);
        localStorage.setItem("userData", JSON.stringify(this.user));
      } catch (error) {
        this.$toast.show(error.response.data.msg, {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>
