<template>
  <div class="card_tab">
    <div class="content-header">
      <Breadcrumbs main="" title="Historia Personal" />
      <button class="small-primary-button" @click="handleChangeStep">
        <i class="fa fa-bookmark-o" aria-hidden="true"></i>
        Guardar y continuar
      </button>
    </div>
    <div class="">
      <div class="row">
        <div class="col-12">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="salud"
                data-bs-toggle="tab"
                data-bs-target="#salud-pane"
                type="button"
                role="tab"
                aria-controls="salud-pane"
                aria-selected="true"
              >
                Salud
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="familiar-tab"
                data-bs-toggle="tab"
                data-bs-target="#familiar-tab-pane"
                type="button"
                role="tab"
                aria-controls="familiar-tab-pane"
                aria-selected="false"
              >
                Familiar
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="escolaridad-tab"
                data-bs-toggle="tab"
                data-bs-target="#escolaridad-tab-pane"
                type="button"
                role="tab"
                aria-controls="escolaridad-tab-pane"
                aria-selected="false"
              >
                Escolaridad
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="laboral-tab"
                data-bs-toggle="tab"
                data-bs-target="#laboral-tab-pane"
                type="button"
                role="tab"
                aria-controls="laboral-tab-pane"
                aria-selected="false"
              >
                Laboral y económico
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="social-tab"
                data-bs-toggle="tab"
                data-bs-target="#social-tab-pane"
                type="button"
                role="tab"
                aria-controls="social-tab-pane"
                aria-selected="false"
              >
                Social, sexual y sentimental
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="salud-pane"
              role="tabpanel"
              aria-labelledby="salud"
              tabindex="0"
            >
              <textarea
                class="form-control textarea_tabs"
                id="exampleFormControlTextarea4"
                rows="3"
                v-model="salud"
              ></textarea>
            </div>
            <div
              class="tab-pane fade"
              id="familiar-tab-pane"
              role="tabpanel"
              aria-labelledby="familiar-tab"
              tabindex="0"
            >
              <textarea
                class="form-control textarea_tabs"
                id="exampleFormControlTextarea4"
                rows="3"
                v-model="familiar"
              ></textarea>
            </div>
            <div
              class="tab-pane fade"
              id="escolaridad-tab-pane"
              role="tabpanel"
              aria-labelledby="escolaridad-tab"
              tabindex="0"
            >
              <textarea
                class="form-control textarea_tabs"
                id="exampleFormControlTextarea4"
                rows="3"
                v-model="escolaridad"
              ></textarea>
            </div>
            <div
              class="tab-pane fade"
              id="laboral-tab-pane"
              role="tabpanel"
              aria-labelledby="laboral-tab"
              tabindex="0"
            >
              <textarea
                class="form-control textarea_tabs"
                id="exampleFormControlTextarea4"
                rows="3"
                v-model="laboral"
              ></textarea>
            </div>
            <div
              class="tab-pane fade"
              id="social-tab-pane"
              role="tabpanel"
              aria-labelledby="social-tab"
              tabindex="0"
            >
              <textarea
                class="form-control textarea_tabs"
                id="exampleFormControlTextarea4"
                rows="3"
                v-model="social"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 ml-4">
      <h5>ANTECEDENTES DE TRATAMIENTO(Tto)</h5>

      <div class="form-check form-check-inline mx-4">
        <input
          class="form-check-input"
          type="checkbox"
          id="niguno"
          v-model="treatment"
          value="option1"
        />
        <label class="form-check-label" v-if="treatment == false" for="niguno"
          >Ninguno</label
        >
        <label class="form-check-label" v-else for="niguno"></label>
      </div>

      <div class="col-12" v-if="treatment == false">
        <h3 class="mt-5 mb-2 h3_personal_history">Ultimo tratamiento</h3>

        <div class="row row">
          <div class="col-lg-4 col-md-4 col-12 mb-3">
            <label class="col-form-label">Año y mes</label>
            <input
              v-model="lastTreatment.date_history"
              class="form-control"
              type="text"
              placeholder="Año y mes"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <label class="col-form-label">Duración del tto. (meses)</label>
            <input
              v-model="lastTreatment.duration_month"
              class="form-control"
              type="text"
              placeholder="Duración del tto. (meses)"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-12 mb-3">
            <label class="col-form-label">Tratamiento</label>
            <select
              v-model="lastTreatment.treatment"
              class="form-select digits"
              id="exampleFormControlSelect9"
            >
              <option>Seleccione</option>
              <option>Psiquiátrico</option>
              <option>Psicológico</option>
              <option>Mixto</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-12 mb-3">
            <label class="col-form-label">Estado de tratamiento</label>

            <select
              v-model="lastTreatment.termination"
              class="form-select digits"
              id="exampleFormControlSelect9"
            >
              <option value="completo">Culminado</option>
              <option value="abandono">Abandonado</option>
              <option value="noregitrado">Sin registro</option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mb-3">
            <label class="col-form-label">Resultados obtenidos</label>
            <textarea
              v-model="lastTreatment.results"
              class="form-control"
              id="exampleFormControlTextarea4"
              rows="3"
            ></textarea>
          </div>
          <div class="col-12" v-if="treatment == true">
            <h3 class="mt-5 mb-2 h3_personal_history">Ultimo tratamiento</h3>

            <div class="family-form row">
              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Año y mes</label>
                <input
                  v-model="lastTreatment.year"
                  class="form-control"
                  type="text"
                  placeholder="Año y mes"
                />
              </div>
              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Duración del tto. (meses)</label>
                <input
                  v-model="lastTreatment.treatmentDuration"
                  class="form-control"
                  type="text"
                  placeholder="Duración del tto. (meses)"
                />
              </div>
              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Tratamiento</label>
                <select
                  v-model="lastTreatment.treatment"
                  class="form-select digits"
                  id="exampleFormControlSelect9"
                >
                  <option>Seleccione</option>
                  <option>Psiquiátrico</option>
                  <option>Psicológico</option>
                  <option>Mixto</option>
                </select>
              </div>

              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Adjuntar informe clínico</label>
                <input class="form-control" type="file" id="formFile" />
              </div>

              <div class="mb-3 col-lg-12 col-md-12 col-12">
                <label class="col-form-label">Resultados obtenidos</label>
                <textarea
                  v-model="lastTreatment.results"
                  class="form-control"
                  id="exampleFormControlTextarea4"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div
              v-for="(item, idx) in listLastTreaments"
              :key="idx"
              class="row"
            >
              <h3 class="mt-5 mb-2 h3_personal_history">
                <i
                  style="cursor: pointer"
                  class="fa fa-minus"
                  @click="removeLastTreatment(idx)"
                ></i>
                Penultimo tratamiento
              </h3>

              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Año y mes</label>
                <input
                  v-model="item.date_history"
                  class="form-control"
                  type="text"
                  placeholder="Año y mes"
                />
              </div>
              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Duración del tto. (meses)</label>
                <input
                  v-model="item.duration_month"
                  class="form-control"
                  type="text"
                  placeholder="Duración del tto. (meses)"
                />
              </div>
              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Tratamiento</label>
                <select
                  v-model="item.treatment"
                  class="form-select digits"
                  id="exampleFormControlSelect9"
                >
                  <option>Seleccione</option>
                  <option>Psiquiátrico</option>
                  <option>Psicológico</option>
                  <option>Mixto</option>
                </select>
              </div>
              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Estado de tratamiento</label>
                <select
                  v-model="item.treatment"
                  class="form-select digits"
                  id="exampleFormControlSelect9"
                >
                  <option value="completo">Completo</option>
                  <option value="abandono">Abandono</option>
                </select>
              </div>
              <div class="mb-3 col-lg-4 col-md-4 col-12">
                <label class="col-form-label">Adjuntar informe clínico</label>
                <input class="form-control" type="file" id="formFile" />
              </div>

              <div class="mb-3 col-lg-12 col-md-12 col-12">
                <label class="col-form-label">Resultados obtenidos</label>
                <textarea
                  v-model="item.results"
                  class="form-control"
                  id="exampleFormControlTextarea4"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <p
              style="cursor: pointer; margin-top: 2rem"
              @click="addLastTreatment(lastTreatment)"
            >
              <i class="fa fa-plus"></i> Penultimo tratamiento
            </p>
          </div>
        </div>

        <div class="row align-items-center">
          <label class="col-form-label">Adjuntar Archivos</label>
          <div
            v-for="(file, index) in test_files"
            :key="index"
            class="col-lg-10 col-md-10 col-12"
            style="margin-bottom: 15px"
          >
            <div class="input-group">
              <input
                v-model="file.name"
                type="text"
                class="form-control"
                :placeholder="`Nombre del archivo` + parseInt(index + 1)"
              />
              <input
                class="form-control"
                type="file"
                @change="(e) => handleFileChange(e, index)"
              />
              <span
                v-if="index !== 0"
                class="input-group-text"
                style="cursor: pointer; color: #bb0c0c; border: none"
                @click="removeFile(index)"
                ><i class="fa fa-minus"></i
              ></span>
            </div>
          </div>
          <div class="col-12 mb-4">
            <button class="btn p-0" @click="addFile">+ Agregar Archivo</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.card_tab {
  background: white;
  padding: 10px;
}

.h3_personal_history {
  font-size: 23px;
}

.textarea_tabs {
  min-height: 200px !important;
}

.page-wrapper .page-body-wrapper .new-consulting .form-check .form-check-input {
  border: 1px solid #616161;
}
</style>
<script>
import { mapActions } from "vuex";
import { uploadFile } from "../../utils/s3Service";
export default {
  props: ["patient"],
  data() {
    return {
      treatment: false,
      selectedFile: "",
      salud: "",
      familiar: "",
      social: "",
      escolaridad: "",
      laboral: "",
      lastTreatment: {
        date_history: "",
        duration_month: null,
        treatment: "",
        termination: "",
        inform_url: "",
        results: "",
      },
      personality_assessment: "",
      psychological_report: "",
      medical_check: "",
      listLastTreaments: [],
      test_files: [
        {
          name: "",
          url_file: "",
        },
      ],
      form: {
        personal_hisotry: [],
      },
    };
  },
  watch: {
    patient: {
      handler(newValue, oldValue) {
        newValue.personal_hisotry?.forEach((item) => {
          if (item.hasOwnProperty("name")) {
            this[item.name.toLowerCase()] = item.content;
          }
          if (item.hasOwnProperty("personal_hisotry")) {
            this.lastTreatment = item.personal_hisotry[0];
          }
        });
      },
    },
  },
  methods: {
    ...mapActions({
      setStep: "patients/setStep",
    }),
    addFile() {
      this.test_files.push({ name: "", url_file: null });
    },
    removeFile(index) {
      this.test_files.splice(index, 1);
    },
    addLastTreatment(lastTreatment) {
      this.listLastTreaments.push({ ...lastTreatment });
    },
    removeLastTreatment(idx) {
      this.listLastTreaments.splice(idx, 1);
    },
    handleFileChangeInform(event) {
      this.selectedFile = event.target.files[0];
      this.uploadFilesInform();
    },
    uploadFilesInform() {
      if (this.selectedFile) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile
        )
          .then((data) => {
            this.lastTreatment.inform_url = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleFileChange(event, index) {
      this.selectedFile = event.target.files[0];
      this.uploadFilesInform1(index);
    },
    uploadFilesInform1(index) {
      if (this.selectedFile) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile
        )
          .then((data) => {
            this.test_files[index].url_file = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleFileChangeInform2(event) {
      this.selectedFile = event.target.files[0];
      this.uploadFilesInform2();
    },
    uploadFilesInform2() {
      if (this.selectedFile) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile
        )
          .then((data) => {
            this.psychological_report = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleFileChangeInform3(event) {
      this.selectedFile = event.target.files[0];
      this.uploadFilesInform3();
    },
    uploadFilesInform3() {
      if (this.selectedFile) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile
        )
          .then((data) => {
            this.medical_check = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleChangeStep() {
      this.listLastTreaments.push(this.lastTreatment);
      this.form.personal_hisotry.push({
        personal_hisotry: this.listLastTreaments,
      });
      let data = [];
      if (this.salud !== "") {
        data.push({
          name: "Salud",
          content: this.salud,
        });
      }
      if (this.familiar !== "") {
        data.push({
          name: "Familiar",
          content: this.familiar,
        });
      }
      if (this.social !== "") {
        data.push({
          name: "Social",
          content: this.social,
        });
      }
      if (this.laboral !== "") {
        data.push({
          name: "Laboral",
          content: this.laboral,
        });
      }
      if (this.escolaridad !== "") {
        data.push({
          name: "Escolaridad",
          content: this.escolaridad,
        });
      }
      this.form.personal_hisotry = [...this.form.personal_hisotry, ...data];
      let test_files = this.test_files;

      this.form.personal_hisotry.push({ test_files: test_files });
      this.$emit("join-data", this.form);
      const step = "treatmentPlan";
      this.setStep({ payload: step });
    },
  },
};
</script>
